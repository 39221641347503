<div id="client-info-modal" class="client-info-modal" uk-modal>
  <div class="uk-modal-dialog" style="width: 50% !important;">
    <button class="uk-modal-close-default" type="button" uk-close></button>

    <div class="uk-modal-header">
      <h2 class="uk-modal-title">Komunikat</h2>

      <button class="uk-modal-close-default" type="button" uk-close></button>
    </div>
    <div class="uk-modal-body">
      <p>Drodzy Użytkownicy,</p>
      <p>
        Stale się rozwijamy i zmieniamy na lepsze, aby dostarczać Wam jeszcze wyższą jakość usług. W związku z tym, obecnie przeprowadzamy modernizację naszego systemu. Chcemy, aby korzystanie z <a href="https://www.romaniaexpress.app">www.romaniaexpress.app</a> było jeszcze wygodniejsze i szybsze!
      </p>

      <p>
        W trakcie prowadzonych prac, od 06 do 13 września, niektóre przesyłki mogą pojawiać się w systemie z opóźnieniem. Bardzo przepraszamy za wszelkie niedogodności, które mogą się z tym wiązać.
      </p>
      <p>
        Jeśli macie jakiekolwiek pytania dotyczące swoich przesyłek, nasi pracownicy są do Waszej dyspozycji. Z przyjemnością udzielą wszelkich informacji – wystarczy napisać na <a href="mailto:hello@romaniaexpress.pl">hello@romaniaexpress.pl</a>.
      </p>
      <p>
        Dziękujemy za Wasze zrozumienie i cierpliwość!
      </p>
      Zespół Romania Express
    </div>
  </div>
</div>
