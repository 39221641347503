

<div uk-grid class="uk-grid-medium">
    <div class="uk-first-column uk-margin-large-top" style="margin: 0 auto; max-width: 600px">
        <div class="uk-vertical-align-middle login-form">
            <div class="align-middle ">

                <div class="uk-card uk-card-default">

                    <div class=" uk-card-body">

                        <div class="uk-text-center uk-margin-bottom">
                            <img src="/assets/img/re-logo.png" class="img-fluid">
                        </div>

                        <form class="" #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">

                            <div class="uk-alert uk-alert-danger" *ngIf="error">
                                {{"login_error" | translate}}
                            </div>

                            <div>
                                <label>{{'email' | translate}}</label>
                                <input name="email" class="uk-input" type="email" required email ngModel>
                            </div>
                            <div>
                                <label>{{'password' | translate}}</label>
                                <input name="password" class="uk-input" type="password" required minlength="6" ngModel>
                            </div>

                            <div class="uk-margin-top">
                                <button [disabled]="!loginForm.valid" type="submit" class="uk-button uk-button-primary uk-width-1-1">
                                    {{'login' | translate}}
                                </button>
                            </div>

                        </form>

                        <hr>

                        <a routerLink="/password-recover" class="uk-link">{{'reset_password' | translate}}</a>

                    </div>



                </div>
            </div>
        </div>
    </div>
</div>
<app-client-info-modal></app-client-info-modal>


