import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {AuthService} from '../../../services/auth/auth.service';
import {UserModel} from '../../../models/user.model';
import {Router} from '@angular/router';
import {Toast, ToastrService} from 'ngx-toastr';
import {ToastsService} from "../../../services/toasts.service";
import {ProblemsService} from "../../../services/problems/problems.service";

declare var UIkit: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    error = false;

    constructor(
        private authService: AuthService,
        private router: Router,
        private toastsService: ToastsService,
        private problemsService: ProblemsService
    ) {
    }

    ngOnInit() {
        this.error = false;
        if (this.authService.isLoggedIn) {
            this.router.navigateByUrl('/dashboard');
        }
    }

    onSubmit(form: NgForm) {
        this.authService.login(form.value).subscribe(
            (response: UserModel) => {
                this.error = false;
                this.authService.setUserModel(response);
                if (response.role<2){
                  this.problemsService.getNewCount().subscribe(
                    (response: any)=> {
                      //UIkit.modal('#client-info-modal').show();

                      if (response.count > 0)
                        this.toastsService.showMessage('Wykryto nowe nieprawidłowości!', 'w');
                      this.router.navigateByUrl('/dashboard');
                    }
                  );
                }
                else {
                  this.router.navigateByUrl('/dashboard');
                }
            }, e => {
                this.error = true;
            }
        );
    }

}
